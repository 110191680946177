<template>
  <section>
        <v-tabs
            v-model="tab"
            background-color="transparent"
            centered
            class="tabs--projects"
        >
            <v-tab
                v-for="(item, i) in items"
                :key="i"
                class="tab--projects align-start"
            >
                <div class="d-flex flex-column justify-start align-center align-self-start size-tab">
                    <h3 class="text-capitalize font-weight-bold body-1 text-xl-h5 mb-5 primary--text">{{ item.city }}</h3>
                    <img :src="item.map" v-bind:class="`map-tab-${i}`" alt="" class="size-map">
                    <p class="txt--tab txt--color text-capitalize body-2 text-xl-subtitle-1 my-5">{{ item.descp }}</p>
                </div>
            </v-tab>

            <v-tabs-items
                v-model="tab"
                class=""
            >
                <v-tab-item
                    v-for="(item,n) in items"
                    :key="n"
                >
                    <v-container>
                        <v-row>
                            <v-col
                                v-for="(pict,i) in item.gallery"
                                :key="i"
                                cols="12"
                                md="3"
                                class="col--media"
                            >
                                <video
                                    class="video--media"    
                                    controls
                                    v-if="pict.type === 'video'"
                                >
                                    <source :src= pict.img type="video/mp4">
                                    Tu navegador no soporta este video
                                </video>
                                <v-img
                                    :src= "noPhoto"
                                    :lazy-src= "noPhoto"
                                    aspect-ratio="1"
                                    v-else-if="pict.img === ''"
                                ></v-img>
                                <v-img
                                    :src= pict.img
                                    :lazy-src= pict.img 
                                    aspect-ratio="1"
                                    v-else
                                ></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
  </section>
</template>

<script>
export default {
    data() {
        return {
            tab: null,
            noPhoto: require('@/assets/gallery/no-foto.png'),
        }
    },
    props: {
        items: {}
    }
}
</script>

<style>

</style>