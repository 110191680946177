<template>
    <section>
        <h1
            class="text-center text-uppercase font-weight-black text-h6 text-md-h4 mb-16"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
            data-aos-offset="300"
        >
            CONOZCA nuestros
            <span class="primary--text">
                proyectos activos
            </span>
        </h1>
        <v-card
            class="card-projects"
            data-aos="fade-down"
            data-aos-duration="1500"
            data-aos-delay="100"
            data-aos-offset="300"
        >
            <maps-services :items="items" />
        </v-card>
        <v-container class="">
            <v-img
                :src="require('@/assets/img/boton-contact.svg')"
                class="text-center dflex align-center btn--contact my-10"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-delay="100"
                data-aos-offset="300"
                
            >
                <a 
                    :href="linkBoton"
                    target="_blank"
                    class="btn--contact-txt text-subtitle-1 text-md-h6 btn--hover"
                >
                    <p class="font-weight-light">Llevamos nuestras máquinas a donde lo necesite</p>
                    <p class="font-weight-bold">¡Contáctenos!</p>
                </a>
            </v-img>
        </v-container>
    </section>
</template>

<script>
import MapsServices from '../../components/MapsServices.vue'
export default {
    components: { MapsServices },
    name: 'SectionServicesProjects',
    data() {
        return {
           items: [
                {
                    id: 1,
                    city: 'Valle del Cauca',
                    map: require('@/assets/maps/map-Valle-del-Cauca.svg'),
                    descp: 'Malla vial de Cali Valle',
                    gallery: [
                        {
                            img: require('@/assets/gallery/cali_1.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/cali_2.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/cali_3.webp'),
                            type: 'img'
                        }
                    ]
                },
                {
                    id: 2,
                    city: 'Bogotá',
                    map: require('@/assets/maps/map-Bogota.svg'),
                    descp: 'Malla vial de Bogotá',
                    gallery: [

                        {
                            img: require('@/assets/gallery/bogota_1.webp'),
                            type: 'img'

                        },
                        {
                            img: require('@/assets/gallery/bogota_2.webp'),
                            type: 'img'

                        },
                        {
                            img: require('@/assets/gallery/bogota_3.webp'),
                            type: 'img'
                        },
                        {
                            id: '4',
                            img: require('@/assets/gallery/bogota_4.webp'),
                            type: 'img'
                        },
                        {
                            id: '5',
                            img: require('@/assets/gallery/bogota_5.webp'),
                            type: 'img'
                        },
                        {
                            id: '6',
                            img: require('@/assets/gallery/bogota_6.webp'),
                            type: 'img'
                        }
                    ]
                },
                {
                    id: 3,
                    city: 'Tolima',
                    map: require('@/assets/maps/map-Tolima.svg'),
                    descp: 'Vía Honda-Mariquita',
                    gallery: [
                        {
                            img: require('@/assets/gallery/honda-mariquita_1.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/honda-mariquita_2.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/honda-mariquita_3.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/honda-mariquita_4.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/honda-mariquita_5.webp'),
                            type: 'img'
                        },
                    ]
                },
                {
                    id: 4,
                    city: 'Casanare',
                    map: require('@/assets/maps/map-Casanare.svg'),
                    descp: 'Malla Vial Urbana de Yopal Casanare',
                    gallery: [
                        {
                            img: require('@/assets/gallery/via_urnaba_yopal1.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/via_urnaba_yopal2.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/via_urnaba_yopal3.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/via_urnaba_yopal4.webp'),
                            type: 'img'
                        },
                    ]
                },
                {
                    id: 5,
                    city: 'Boyacá',
                    map: require('@/assets/maps/map-Boyaca.svg'),
                    descp: 'Concesion Briceño-Tunja-Sogamoso',
                    gallery: [
                        {
                            img: '',
                            type: ''
                        },
                        {
                            img: '',
                            type: ''
                        },
                        {
                            img: '',
                            type: ''
                        },
                        {
                            img: '',
                            type: ''
                        },
                    ]
                },
                {
                    id: 6,
                    city: 'Santander',
                    map: require('@/assets/maps/map-Santander.svg'),
                    descp: 'Aeropuerto Palonegro de Bucaramanga',
                    gallery: [
                        {
                            img: require('@/assets/gallery/aeropuerto-bucaramanga-1.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/aeropuerto-bucaramanga-2.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/aeropuerto-bucaramanga-3.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/aeropuerto-bucaramanga-4.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/aeropuerto-bucaramanga-5.webp'),
                            type: 'img'
                        },
                    ]
                },
                {
                    id: 7,
                    city: 'Antioquia',
                    map: require('@/assets/maps/map-Antioquia.svg'),
                    descp: 'Vía Dabeiba Mutata',
                    gallery: [
                        {
                            img: require('@/assets/gallery/Dabeiba-Mutata_1.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/Dabeiba-Mutata_2.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/Dabeiba-Mutata_3.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/Dabeiba-Mutata_4.webp'),
                            type: 'img'
                        },
                        {
                            img: require('@/assets/gallery/Dabeiba-Mutata_5.webp'),
                            type: 'img'
                        },
                    ]
                },
            ],
            linkBoton: 'http://eepurl.com/hnzyTv'
        }
    },
}
</script>

<style lang="scss">
.card-projects {
    box-shadow: none !important;
    [role=tablist] {
        max-height: 50vw;
        height: auto;
    }
    .tab--projects {
        .size-tab { width: 100%; }
        .txt--color { color: #2d333b; }
        width: 16%;
        .size-map {
            width: 100%;
            height: 15vw;
            text-align: center;
            margin: auto;
        }
        /* .map-tab-0 { width: 106%;}
        .map-tab-1 { width: 40% }
        .map-tab-2 { width: 92%; }
        .map-tab-3 { width: 84%; }
        .map-tab-4 { width: 73%; }

        //Responsive
        @media (min-width: 1920px) {
            .map-tab-0 { width: 100%;}
            .map-tab-1 { width: 54% }
            .map-tab-2 { width: 126%; }
            .map-tab-3 { width: 105%; }
            .map-tab-4 { width: 68%; }
        } */
    }
}
.btn--contact {
    .btn--contact-txt {
        color: white !important;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        p {
            margin-bottom: 0;
            &:nth-child(1) { line-height: 1vw; }
        }
    }
    .v-image__image {
        background-size: 80% 80%;
       @media (min-width: 720px){ background-size: 70% 70%; }
       @media (min-width: 1264px){ background-size: 50% 50%; }
    }
}
.btn--hover {
    transition: transform .2s;
    &:hover { transform: scale(1.2); }
}
.col--media {
    display: flex;
    align-items: center;
    .video--media { width: 100%; }
}
</style>